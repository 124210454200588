import { graphql } from "gatsby";
import React, {useEffect } from "react";
import styled, { keyframes } from "styled-components";
import PageLayout from "../components/PageLayout";
import { Container } from "react-bootstrap";
import { api } from "../utilities/api";

const ServeyForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 20vh; /* Adjust the height as needed */
  color: rgb(4, 148, 202);
`;

const CheckmarkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(4, 148, 202);
  margin-bottom: 8px;
`;

const stroke = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const Checkmark = styled.svg`
  width: 30px;
  .checkmark-circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #fff;
    fill: none;
    animation: ${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  .checkmark-check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #fff;
    fill: none;
    animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.6s forwards;
  }
`;

const Survey = () => {
  useEffect(() => {
    // Parse the search query parameters
    const searchParams = new URLSearchParams(window.location.search);
    const tid = searchParams.get('tid');
    const satisfaction = searchParams.get('satisfaction');
    const comment = searchParams.get('comment');

    // Post request to backend API
    const postFeedback = (tid, satisfaction) => {
      api.submitSurvey(tid, satisfaction, comment);
    }

    // Call the function if tid and satisfaction are present
    if (tid && satisfaction) {
      postFeedback(tid, satisfaction);
    }
  }, []); 


  return (
    <PageLayout>
      <title>Survey | PhoneBox</title>
      <Container>
        <ServeyForm>
          <CheckmarkContainer>
            <Checkmark viewBox="0 0 52 52">
              <circle className="checkmark-circle" cx="26" cy="26" r="25"/>
              <path className="checkmark-check" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </Checkmark>
          </CheckmarkContainer>
          <h4>Thanks for the feedback!</h4>
        </ServeyForm>
      </Container>
    </PageLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Survey;
